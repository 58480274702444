import React from 'react';

const PolitiqueConfidentialite = () => {
  return (
    <div className="container mt-32 mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-6 text-center">Politique de confidentialité</h1>
      <p className="text-gray-600 text-center mb-8 max-w-3xl mx-auto">
        Cette politique de confidentialité explique comment VGom Creation collecte, utilise, partage et protège vos informations personnelles lorsque vous visitez notre site web ou utilisez nos services.
      </p>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">1. Informations collectées</h2>
        <p className="text-gray-700 leading-relaxed">
          Nous collectons des informations personnelles que vous fournissez volontairement, telles que votre nom, adresse e-mail, numéro de téléphone, et informations de paiement lors de vos commandes. 
          Nous collectons également des informations techniques automatiquement, comme votre adresse IP, le type de navigateur, les pages visitées, et les cookies.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">2. Utilisation des informations</h2>
        <p className="text-gray-700 leading-relaxed">
          Vos informations personnelles sont utilisées pour :
        </p>
        <ul className="list-disc ml-6 text-gray-700">
          <li>Traiter vos commandes et paiements via des prestataires comme Stripe ;</li>
          <li>Répondre à vos demandes et assurer le support client ;</li>
          <li>Améliorer nos services et personnaliser votre expérience utilisateur ;</li>
          <li>Vous envoyer des communications marketing si vous avez donné votre consentement explicite.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">3. Partage des informations</h2>
        <p className="text-gray-700 leading-relaxed">
          Nous partageons vos informations personnelles uniquement dans les cas suivants :
        </p>
        <ul className="list-disc ml-6 text-gray-700">
          <li>Avec nos partenaires de paiement (par ex., Stripe) pour sécuriser vos transactions ;</li>
          <li>Avec des prestataires tiers de confiance pour l’hébergement et l’analyse de données ;</li>
          <li>Pour se conformer à des obligations légales ou des demandes des autorités compétentes.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">4. Cookies et technologies de suivi</h2>
        <p className="text-gray-700 leading-relaxed">
          Nous utilisons des cookies pour analyser la fréquentation de notre site et améliorer ses fonctionnalités. Vous pouvez gérer ou désactiver les cookies via les paramètres de votre navigateur.
        </p>
        <p className="text-gray-700 leading-relaxed">
          Types de cookies utilisés :
        </p>
        <ul className="list-disc ml-6 text-gray-700">
          <li>Cookies essentiels pour assurer le bon fonctionnement du site ;</li>
          <li>Cookies d’analyse pour comprendre l’utilisation du site ;</li>
          <li>Cookies de marketing (si applicable).</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">5. Sécurité des données</h2>
        <p className="text-gray-700 leading-relaxed">
          Nous mettons en œuvre des mesures techniques et organisationnelles pour protéger vos informations personnelles contre tout accès non autorisé, divulgation, altération ou destruction. 
          Toutefois, aucun système n'est totalement sécurisé, et nous ne pouvons garantir une sécurité absolue.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">6. Vos droits</h2>
        <p className="text-gray-700 leading-relaxed">
          Conformément au RGPD, vous disposez des droits suivants :
        </p>
        <ul className="list-disc ml-6 text-gray-700">
          <li>Accéder à vos données personnelles ;</li>
          <li>Demander la rectification ou la suppression de vos données ;</li>
          <li>Limiter ou vous opposer au traitement de vos données ;</li>
          <li>Retirer votre consentement à tout moment pour les communications marketing ;</li>
          <li>Déposer une plainte auprès d'une autorité de protection des données si nécessaire.</li>
        </ul>
        <p className="text-gray-700 leading-relaxed">
          Pour exercer vos droits, contactez-nous à <a href="mailto:contact@vgomcreation.fr" className="text-blue-500 hover:underline">contact@vgomcreation.fr</a>.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">7. Modifications de la politique</h2>
        <p className="text-gray-700 leading-relaxed">
          Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Les modifications seront publiées sur cette page. 
          Nous vous encourageons à consulter cette politique régulièrement pour rester informé.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">8. Contact</h2>
        <p className="text-gray-700 leading-relaxed">
          Pour toute question ou demande relative à cette politique, vous pouvez nous contacter par e-mail à
          <a href="mailto:contact@vgomcreation.fr" className="text-blue-500 hover:underline ml-1">contact@vgomcreation.fr</a>.
        </p>
      </section>
    </div>
  );
};

export default PolitiqueConfidentialite;
