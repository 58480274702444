export const servicesData = [
  {
    id: 1,
    title: "Création de sites vitrine moderne",
    image: "https://res.cloudinary.com/dwcdxkpfk/image/upload/v1732807706/1_hmhxoj.webp",
    items: [
      "Design attractif et professionnel",
      "Adaptabilité mobile, tablette et ordinateur",
      "Personnalisation simple et rapide"
    ]
  },
  {
    id: 2,
    title: "Optimisation de petits sites web",
    image: "https://res.cloudinary.com/dwcdxkpfk/image/upload/v1732807705/2_cmqhjz.webp",
    items: [
      "Amélioration des performances globales",
      "Optimisation des images pour plus de rapidité",
      "Simplification de la structure pour le SEO"
    ]
  },
  {
    id: 3,
    title: "SEO (Référencement naturel)",
    image: "https://res.cloudinary.com/dwcdxkpfk/image/upload/v1732807705/3_psnqe8.webp",
    items: [
      "Audit SEO facile à comprendre",
      "Optimisation du contenu pour le SEO",
      "Recherche de mots-clés basiques"
    ]
  },
  {
    id: 4,
    title: "Accessibilité web et conformité WCAG",
    image: "https://res.cloudinary.com/dwcdxkpfk/image/upload/v1732807705/4_mkiknc.webp",
    items: [
      "Analyse d'accessibilité de base",
      "Application des normes essentielles WCAG 2.1",
      "Amélioration de la navigation pour tous"
    ]
  },
  {
    id: 5,
    title: "Maintenance et support",
    image: "https://res.cloudinary.com/dwcdxkpfk/image/upload/v1732807705/5_jf7ogo.webp",
    items: [
      "Résolution rapide des bugs",
      "Mises à jour régulières pour sécurité",
      "Assistance technique simple"
    ]
  },
  {
    id: 6,
    title: "Accompagnement & conseils personnalisés",
    image: "https://res.cloudinary.com/dwcdxkpfk/image/upload/v1732807705/6_vybz99.webp",
    items: [
      "Aide pour choisir un design adapté",
      "Recommandation d'hébergeurs abordables",
      "Conseils pour acheter un nom de domaine"
    ]
  }
];
