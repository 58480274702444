import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { getAllProjects } from '../api';
import Accordion from '../components/Accordion';
import { ArrowLeft } from 'lucide-react';

const Shop = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null);
  const [accordionState, setAccordionState] = useState({
    howItWorks: false,
    faq: false,
  });

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const data = await getAllProjects();
        const formattedProjects = data.data
          .filter((project) => project.vendable)
          .map((project) => ({
            id: project.id,
            title: project.titre,
            image: project.image?.formats?.medium?.url
              ? `${project.image.formats.medium.url}`
              : '/placeholder-image.jpg',
            price: project.price || 0,
            description: project.description,
            paymentLink: project.paymentLink,
          }));
        setProjects(formattedProjects);
      } catch (err) {
        setError('Erreur lors de la récupération des projets.');
      }
    };

    fetchProjects();
  }, []);

  const handleRedirectToPayment = (paymentLink) => {
    if (paymentLink) {
      window.location.href = paymentLink;
    } else {
      alert('Lien de paiement indisponible.');
    }
  };

  const toggleAccordion = (key) => {
    setAccordionState((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  if (error) {
    return <p className="text-red-500 text-center mt-8">{error}</p>;
  }

  return (
    <div className="min-h-screen bg-white py-20">
      <div className="text-center pt-10 md:pt-20">
        <h1 className="text-4xl font-bold mb-4">Boutique</h1>
        <p className="text-gray-500 max-w-xl mx-auto mb-5">
          Un projet vous intéresse ? Choisissez un modèle et cliquez sur
          <strong> "Acheter" </strong> pour démarrer votre aventure et créer un
          site professionnel à votre image, en toute autonomie. Pour en savoir
          plus, consultez les sections{' '}
          <strong>"Comment ça fonctionne ?"</strong> et <strong>"FAQ"</strong>{' '}
          ci-dessous.
        </p>

        {/* Bouton retour */}
        <div className="container mx-auto px-4">
          <button
            onClick={() => navigate('/')}
            className="flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-colors mb-8 group"
          >
            <ArrowLeft className="w-5 h-5 transform group-hover:-translate-x-1 transition-transform" />
            <span>Retour à l'accueil</span>
          </button>
        </div>

        <p className="text-gray-600 mb-4 md:mb-6 text-sm font-medium">
          <span className="inline-flex items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-eastern"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13 16h-1v-4h-1m1-4h.01M21 8.5a5.5 5.5 0 11-11 0 5.5 5.5 0 0111 0zm-2.5 0a3 3 0 11-6 0 3 3 0 016 0zm-7.78 8.094A5.478 5.478 0 018 18c-1.654 0-3.157-.572-4.372-1.52A11.971 11.971 0 0112 21c3.042 0 5.838-1.134 7.956-3H19.5z"
              />
            </svg>
            Paiement sécurisé avec Stripe
          </span>
        </p>
      </div>

      <div className="container mx-auto px-4 grid gap-8 md:grid-cols-2 xl:grid-cols-3">
        {projects.map((project) => (
          <div
            key={project.id}
            className="relative bg-white rounded-lg overflow-hidden shadow-lg transition-transform transform hover:scale-105"
          >
            {project.image ? (
              <img
                src={project.image}
                alt={project.title}
                className="w-full h-64 object-cover"
              />
            ) : (
              <div className="w-full h-64 bg-gray-200 flex items-center justify-center">
                <span className="text-gray-500">Aucune image</span>
              </div>
            )}
            <div className="p-4">
              <h2 className="text-lg font-semibold">{project.title}</h2>
              <p className="text-gray-600">
                Prix : {project.price ? `${project.price} €` : 'Non disponible'}
              </p>
              {project.paymentLink ? (
                <button
                  className="bg-black text-white py-2 px-4 rounded hover:bg-eastern transition mt-4"
                  onClick={() => handleRedirectToPayment(project.paymentLink)}
                >
                  Acheter
                </button>
              ) : (
                <p className="text-red-500 mt-4">
                  Lien de paiement non disponible
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
      {/* Accordion sections */}
      <div className="mt-20 max-w-4xl mx-auto">
        <Accordion
          title="Comment ça fonctionne ?"
          isOpen={accordionState.howItWorks}
          onToggle={() => toggleAccordion('howItWorks')}
        >
          <ol className="text-gray-600 list-decimal list-inside space-y-2">
            <li>
              <strong>Choisissez un modèle :</strong> Parcourez nos designs et
              sélectionnez celui qui correspond le mieux à vos besoins.
            </li>
            <li>
              <strong>Passez commande :</strong> Cliquez sur <em>"Acheter"</em>{' '}
              pour effectuer un paiement sécurisé via Stripe. Vous recevrez
              immédiatement un reçu par email.
            </li>
            <li>
              <strong>Recevez vos fichiers :</strong> Les fichiers du projet
              (code source au format .zip et le guide d'installation en pdf)
              vous seront envoyés par email via VGom Creation. Si vous ne les
              recevez pas dans l’heure, veuillez nous{' '}
              <NavLink to="/nous-contacter" className="text-blue-500 underline">
                contacter, on se fera un plaisir de vous aider.
              </NavLink>
              .
            </li>
            <li>
              <strong>Personnalisez facilement :</strong> Adaptez les textes et
              les images à votre contenu grâce à une documentation claire et
              détaillée.
            </li>
            <li>
              <strong>Besoin d’aide ?</strong> Réservez une visioconférence
              personnalisée via la page{' '}
              <NavLink to="/nous-contacter" className="text-blue-500 underline">
                Contact
              </NavLink>
              .
            </li>
            <li>
              <strong>Lisez nos CGV et CGU :</strong> Consultez nos{' '}
              <NavLink to="/cgv" className="text-blue-500 underline">
                Conditions Générales de Vente (CGV)
              </NavLink>{' '}
              et{' '}
              <NavLink to="/cgu" className="text-blue-500 underline">
                Conditions Générales d’Utilisation (CGU)
              </NavLink>{' '}
              avant de passer commande. Vous pouvez également les{' '}
              <a
                href="Documents/cgv-vgomcreation.pdf"
                download="cgv.pdf"
                className="text-blue-500 underline"
              >
                télécharger ici (CGV)
              </a>{' '}
              et{' '}
              <a
                href="Documents/cgu-vgomcreation.pdf"
                download="cgu.pdf"
                className="text-blue-500 underline"
              >
                télécharger ici (CGU)
              </a>
              .
            </li>
          </ol>
        </Accordion>

        <Accordion
          title="FAQ"
          isOpen={accordionState.faq}
          onToggle={() => toggleAccordion('faq')}
          className="mt-8"
        >
          <div className="text-gray-600">
            <p className="mb-4">
              <strong>Q : Que faire si je rencontre des difficultés ?</strong>
              <br />R : Pas de panique ! Vous pouvez réserver un rendez-vous en
              visioconférence via la page{' '}
              <NavLink to="/nous-contacter" className="text-blue-500 underline">
                Contact
              </NavLink>{' '}
              pour bénéficier d’un accompagnement personnalisé. Nous serons là
              pour vous guider étape par étape.
            </p>
            <p className="mb-4">
              <strong>Q : Ai-je besoin de compétences techniques ?</strong>
              <br />R : Pas besoin d’être un expert ! Une connaissance de base
              de l’utilisation d’un ordinateur et d’Internet est suffisante.
              Notre documentation détaillée vous guidera pas à pas pour
              installer et personnaliser votre site.
            </p>
            <p className="mb-4">
              <strong>Q : Puis-je utiliser un domaine personnalisé ?</strong>
              <br />R : Absolument ! Nous vous expliquons dans la documentation
              comment connecter un domaine personnalisé à votre site pour
              renforcer votre identité professionnelle.
            </p>
            <p className="mb-4">
              <strong>
                Q : Mon site sera-t-il immédiatement accessible après l’achat ?
              </strong>
              <br />R : Oui, vous pourrez déployer votre site sur une plateforme
              gratuite, grâce à la documentation incluse. Vous obtiendrez un
              lien par défaut de l'hébergeur pour partager votre site
              immédiatement.
            </p>
            <p className="mb-4">
              <strong>Q : Y a-t-il des coûts supplémentaires ?</strong>
              <br />R : Pas de frais cachés ! Seulement si vous choisissez
              d’utiliser un domaine personnalisé (comme www.votresite.com), cela
              vous coûtera environ 20€/an chez un fournisseur de domaine, mais
              ce n’est pas obligatoire.
            </p>
          </div>
        </Accordion>
      </div>
    </div>
  );
};

export default Shop;
