import React, { createContext, useState, useEffect } from 'react';
import {
  toggleArticleLike,
  addComment,
  getComments,
  incrementView,
  API_URL,
} from '../api';

export const BlogContext = createContext();

export const BlogProvider = ({ children, userId }) => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch(`${API_URL}/api/blogs?populate=*`);
        if (!response.ok)
          throw new Error('Erreur lors de la récupération des articles');
        const data = await response.json();

        // Récupérer les likes et commentaires stockés dans localStorage
        const storedLikes =
          JSON.parse(localStorage.getItem('likedArticles')) || {};
        const storedComments =
          JSON.parse(localStorage.getItem('commentCounts')) || {};

        // Mettre à jour les articles avec les données de localStorage
        const updatedArticles = data.data.map((article) => ({
          ...article,
          hasLiked: storedLikes[article.id] || false, // Likes sauvegardés
          commentaire_count:
            storedComments[article.id] || article.commentaire_count || 0, // Commentaires sauvegardés
        }));

        setArticles(updatedArticles);
        setError(null);
      } catch (error) {
        setError('Impossible de charger les articles.');
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, [userId]);

  useEffect(() => {
    console.log('Articles mis à jour dans le contexte :', articles);
  }, [articles]);

  const handleUpdateViewCount = async (articleId) => {
    try {
      const newViewCount = await incrementView(articleId); // Appelle le backend
      console.log(`Nouvelle vue pour l'article ${articleId} :`, newViewCount);
      if (newViewCount !== null) {
        setArticles((prevArticles) => {
          const updatedArticles = prevArticles.map((a) =>
            a.id === articleId ? { ...a, vue_count: newViewCount } : a
          );
          console.log('Articles mis à jour :', updatedArticles);
          return updatedArticles;
        });
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour des vues:', error);
    }
  };
  
  const handleLikeToggle = async (articleId) => {
    try {
      const response = await toggleArticleLike(articleId);

      setArticles((prevArticles) =>
        prevArticles.map((article) =>
          article.id === articleId
            ? {
                ...article,
                likes_count: response.data.likes_count,
                hasLiked: response.data.hasLiked,
              }
            : article
        )
      );
      // Sauvegardez l'état du like dans localStorage
      setLikeInLocalStorage(articleId, response.data.hasLiked);
    } catch (error) {
      console.error('Erreur lors du toggle du like:', error);
    }
  };

  // Fonction pour sauvegarder le like dans localStorage
  const setLikeInLocalStorage = (articleId, hasLiked) => {
    const storedLikes = JSON.parse(localStorage.getItem('likedArticles')) || {};
    storedLikes[articleId] = hasLiked;
    localStorage.setItem('likedArticles', JSON.stringify(storedLikes));
  };

  // Fonction pour sauvegarder le nombre de commentaires dans localStorage
  const setCommentCountInLocalStorage = (articleId, commentCount) => {
    const storedComments =
      JSON.parse(localStorage.getItem('commentCounts')) || {};
    storedComments[articleId] = commentCount;
    localStorage.setItem('commentCounts', JSON.stringify(storedComments));
  };

  const handleAddComment = async (articleId, commentData) => {
    try {
      console.log('Payload envoyé :', commentData);
      console.log('Article ID :', articleId);

      const newComment = await addComment(articleId, commentData);
      console.log('Nouveau commentaire créé :', newComment);

      const updatedComments = await getComments(articleId);
      console.log('Commentaires mis à jour :', updatedComments);

      const updatedCommentCount = updatedComments.length;

      // Mettre à jour le contexte
      setArticles((prevArticles) =>
        prevArticles.map((a) =>
          a.id === articleId
            ? {
                ...a,
                commentaire_count: updatedCommentCount,
                comments: updatedComments,
              }
            : a
        )
      );

      // Sauvegarder dans localStorage
      setCommentCountInLocalStorage(articleId, updatedCommentCount);
    } catch (error) {
      console.error(
        'Erreur lors de l’ajout du commentaire:',
        error.response?.data || error.message
      );
    }
  };

  const updateCommentCount = (articleId, newCount) => {
    setArticles((prevArticles) =>
      prevArticles.map((article) =>
        article.id === articleId
          ? {
              ...article,
              commentaire_count: newCount, // Utilisez la valeur exacte
            }
          : article
      )
    );
  };  

  return (
    <BlogContext.Provider
      value={{
        articles,
        loading,
        error,
        handleUpdateViewCount,
        handleLikeToggle,
        handleAddComment,
        getComments,
        updateCommentCount,
      }}
    >
      {children}
    </BlogContext.Provider>
  );
};
