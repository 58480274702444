import React, { useState } from 'react';
import { Eye, EyeOff, CheckCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { register } from '../api';

function Inscription() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const response = await register(username, email, password); // Appel de la fonction register
      if (response.jwt) {
        setSuccess(true);
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      }
    } catch (err) {
      setError(
        err.response?.data?.error?.message || 'Erreur lors de l’inscription'
      );
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50">
      <div className="w-full max-w-lg bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold text-gray-800 mb-4 text-center">
          Créer un compte
        </h2>
        <p className="text-gray-500 text-center mb-6">
          Rejoignez-nous en créant votre compte. C'est rapide et facile !
        </p>

        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        {success && (
          <div className="flex items-center text-green-500 mb-4">
            <CheckCircle className="mr-2" />
            <span>Inscription réussie ! Redirection vers la connexion...</span>
          </div>
        )}

        <form onSubmit={handleRegister} className="space-y-6">
          {/* Champ Nom d'utilisateur */}
          <div>
            <label className="block font-semibold text-gray-700 mb-1">
              Nom d'utilisateur
            </label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
            />
          </div>

          {/* Champ E-mail */}
          <div>
            <label className="block font-semibold text-gray-700 mb-1">
              E-mail
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
            />
          </div>

          {/* Champ Mot de passe */}
          <div>
            <label className="block font-semibold text-gray-700 mb-1">
              Mot de passe
            </label>
            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full px-4 py-2 border rounded-lg pr-12 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
              >
                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </button>
            </div>
          </div>

          {/* Bouton S'inscrire */}
          <button
            type="submit"
            className={`w-full bg-gradient-to-br from-purple-800 to-indigo-600 text-white py-3 rounded-lg font-medium transition-all duration-300 
    hover:from-indigo-700 hover:to-purple-600 hover:shadow-lg disabled:opacity-50 relative`}
          >
            S'inscrire
          </button>
        </form>

        {/* Lien vers Connexion */}
        <div className="text-center mt-6">
          <p className="text-gray-600">
            Déjà un compte ?{' '}
            <button
              className="text-purple-600 hover:underline"
              onClick={() => navigate('/login')}
            >
              Connectez-vous
            </button>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Inscription;
