import React from 'react';
import logo from '../assets/logo-footer.svg';
import { Link } from 'react-router-dom';
import { Smartphone, Mail, MapPin } from 'lucide-react';
import {
  FaLinkedin,
  FaGithub,
  FaSlack,
  FaDiscord,
  FaBehance,
  FaPinterest,
  FaTwitter,
  FaInstagram,
  FaFacebook,
  FaYoutube,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-black text-white">
      <div className="container mx-auto px-4 py-16">
        <div className="flex flex-col md:flex-row justify-center gap-8 md:space-x-20">
          {/* Logo */}
          <div className="flex justify-center md:justify-start">
            <img src={logo} alt="Logo" className="h-24 md:h-32 w-auto mb-2" />
          </div>

          {/* Informations de contact */}
          <div className="flex flex-col items-center md:items-start space-y-1 text-left text-sm md:text-base">
            <div className="flex items-center space-x-2">
              <Smartphone size={20} className="md:size-26" />
              <p>07 67 60 34 49</p>
            </div>
            <div className="flex items-center space-x-2">
              <Mail size={20} className="md:size-26" />
              <p>contact@vgomcreation.fr</p>
            </div>
            <div className="flex items-center space-x-2">
              <MapPin size={20} className="md:size-26" />
              <p>Paris & Périphérie, France</p>
            </div>
          </div>

          {/* Liens utiles */}
          <div className="flex flex-col items-center md:items-start space-y-1 text-left text-sm md:text-base">
            <Link
              to="/nous-contacter"
              className="text-white hover:text-eastern transition-colors"
            >
              Nous contacter
            </Link>
            <Link
              to="/mentions-legales"
              className="text-white hover:text-eastern transition-colors"
            >
              Mentions légales
            </Link>
            <Link
              to="/cgu"
              className="text-white hover:text-eastern transition-colors"
            >
              CGU
            </Link>
            <Link
              to="/cgv"
              className="text-white hover:text-eastern transition-colors"
            >
              CGV
            </Link>
            <Link
              to="/politique-confidentialite"
              className="text-white hover:text-eastern transition-colors"
            >
              Politique de confidentialité
            </Link>
          </div>
        </div>
      </div>

      {/* Barre de séparation et icônes des réseaux sociaux */}
      <div className="mt-8 md:mt-10">
        <hr className="border-gray-800 mb-4 md:mb-6" />
        <div className="flex flex-wrap justify-center space-x-4 pb-4 md:pb-6">
          {[
            {
              href: 'https://www.linkedin.com/in/vincent-vgom',
              icon: FaLinkedin,
              label: 'LinkedIn',
            },
            { href: 'https://github.com/der411', icon: FaGithub, label: 'GitHub' },
            { href: 'https://webdev-hyw5242.slack.com/team/U06LYPWHUUB', icon: FaSlack, label: 'Slack' },
            { href: 'https://discord.gg/QqKW5Xskpp', icon: FaDiscord, label: 'Discord' },
            {
              href: 'https://www.behance.net/vintedvv',
              icon: FaBehance,
              label: 'Behance',
            },
            {
              href: 'https://www.pinterest.com/vaitilingomvincent',
              icon: FaPinterest,
              label: 'Pinterest',
            },
            { href: 'https://x.com/VaitilingomV', icon: FaTwitter, label: 'Twitter' },
            {
              href: 'https://instagram.com/vgom_creation',
              icon: FaInstagram,
              label: 'Instagram',
            },
            {
              href: 'https://www.facebook.com/profile.php?id=61569504386704',
              icon: FaFacebook,
              label: 'Facebook',
            },
            { href: 'https://www.youtube.com/@vincentvgom', icon: FaYoutube, label: 'YouTube' },
          ].map(({ href, icon: Icon, label }) => (
            <a
              key={label}
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={label}
              className="text-xl md:text-2xl hover:text-gray-500 transition-colors"
            >
              <Icon />
            </a>
          ))}
        </div>
        {/* Paragraphe de Copyright */}
        <p className="text-center text-xs md:text-sm text-gray-500 mt-4 md:mt-6 pb-4">
          © {new Date().getFullYear()} VGom Creation. Tous droits réservés.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
