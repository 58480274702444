import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../api';
import { FaEye, FaEyeSlash, FaCheckCircle } from 'react-icons/fa';
import { MdLockOutline, MdCheckCircleOutline } from 'react-icons/md';

function Connexion() {
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(false);
    try {
      const response = await login(identifier, password); // Appel de la fonction login depuis api.js
      if (response.jwt) {
        localStorage.setItem('jwt', response.jwt);
        setIdentifier('');
        setPassword('');
        setSuccess(true);
        setError(null);
        setTimeout(() => {
          navigate('/');
        }, 3000);
      }
    } catch (err) {
      setError(err.response?.data?.error?.message || 'Erreur de connexion');
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50">
      <div className="w-full max-w-lg bg-white p-8 rounded-lg shadow-lg relative">
        {/* Icône de connexion ou déconnexion */}
        <div className="absolute top-4 right-4 text-gray-600">
          {success ? (
            <MdCheckCircleOutline size={40} className="text-green-500" />
          ) : (
            <MdLockOutline size={40} className="text-purple-500" />
          )}
        </div>

        <h2 className="text-3xl font-bold text-gray-800 mb-4 text-center">
          Connexion
        </h2>
        <p className="text-gray-500 text-center mb-6">
          Connectez-vous avec vos informations pour accéder à votre compte.
        </p>

        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        {success && (
          <div className="flex items-center text-green-500 mb-4">
            <FaCheckCircle className="mr-2" />
            <span>C</span>
          </div>
        )}

        <form onSubmit={handleLogin} className="space-y-6">
          {/* Champ Identifiant */}
          <div>
            <label className="block font-semibold text-gray-700 mb-1">
              Nom d'utilisateur ou e-mail
            </label>
            <input
              type="text"
              value={identifier}
              onChange={(e) => setIdentifier(e.target.value)}
              required
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
            />
          </div>

          {/* Champ Mot de passe */}
          <div>
            <label className="block font-semibold text-gray-700 mb-1">
              Mot de passe
            </label>
            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full px-4 py-2 border rounded-lg pr-12 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
              />
              <button
                type="button"
                onClick={toggleShowPassword}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>

          {/* Bouton Connexion */}
          <button
            type="submit"
            className={`w-full bg-gradient-to-br from-purple-800 to-indigo-600 text-white py-3 rounded-lg font-medium transition-all duration-300 
                       hover:from-indigo-700 hover:to-purple-600 hover:shadow-lg disabled:opacity-50 relative`}
          >
            Se connecter
          </button>
        </form>

        {/* Lien vers Inscription */}
        <div className="text-center mt-6">
          <p className="text-gray-600">
            Pas encore de compte ?{' '}
            <button
              className="text-purple-600 hover:underline"
              onClick={() => navigate('/inscription')}
            >
              Inscrivez-vous
            </button>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Connexion;
