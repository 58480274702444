import React, { useState, useEffect } from 'react';
import { getAllProjects } from '../api';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const data = await getAllProjects(); // Appel de la fonction getAllProjects depuis api.js
        setProjects(data.data); // Accès aux données renvoyées par Strapi
      } catch (err) {
        setError('Erreur lors de la récupération des projets.');
      }
    };

    fetchProjects();
  }, []);

  if (error) {
    return <p className="text-red-500 text-center">{error}</p>;
  }

  return (
    <div className="bg-white text-black py-6 lg:py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold mb-6 lg:mb-2">Nos projets</h2>
        <Link
          to="/portfolio"
          className="flex items-center justify-end mb-6 text-gray-600 hover:text-eastern transition-colors group"
        >
          Voir tous les projets
          <ArrowRight
            className="ml-2 group-hover:translate-x-2 transition-transform"
            size={20}
          />
        </Link>
        <p className="text-gray-400 mb-8">Réalisation et Innovation</p>
        <p className="mb-9 md:mb-16 max-w-2xl text-gray-600">
          Découvrez nos projets qui allient créativité,
          fonctionnalités avancées et design moderne.
        </p>

        {/* Liste des projets */}
        <div className="text-sm md:space-y-8">
          {projects.slice(0, 3).map((project) => (
            <Link
              to={`/portfolio`}
              key={project.id}
              className="block"
            >
              <div className="group border-b border-gray-200 py-8 hover:bg-gray-50 transition-all cursor-pointer">
                <div className="flex justify-between items-center">
                  <h3 className="text-2xl md:text-4xl font-light">
                    {project?.titre || 'Titre non disponible'}
                  </h3>
                  <p className="text-gray-600">
                    {project?.description || 'Description non disponible'}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;
