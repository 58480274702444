import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from 'react-share';

import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
} from 'react-share';

const BoutonsPartage = () => {
  const url = "https://www.vgomcreation.fr";
  const title = "Découvrez VGom Creation - Création de sites web sur mesure !";
  const hashtags = ["webdesign", "VGomCreation", "sitesweb"];

  return (
    <div className="social-share">
      {/* Icône Partager */}
      <div className="share-header">
        <span className="share-text">Partager</span>
      </div>

      {/* Icônes de réseaux sociaux */}
      <FacebookShareButton url={url} quote={title} hashtags={hashtags}>
        <FacebookIcon size={50} round />
      </FacebookShareButton>
      <TwitterShareButton url={url} title={title} hashtags={hashtags}>
        <TwitterIcon size={50} round />
      </TwitterShareButton>
      <LinkedinShareButton url={url} title={title} hashtags={hashtags}>
        <LinkedinIcon size={50} round />
      </LinkedinShareButton>
      <WhatsappShareButton url={url} title={title} hashtags={hashtags}>
        <WhatsappIcon size={50} round />
      </WhatsappShareButton>
    </div>
  );
};

export default BoutonsPartage;
