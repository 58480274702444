import React from 'react';
import { motion } from 'framer-motion';
import { servicesData } from '../data/servicesData';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

const NosOffres = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-white py-16">
      {/* Section Nos Offres */}
      <div className="container mt-14 md:mt-24 mx-auto px-4">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold mb-2">Nos Offres</h1>
          <p className="text-gray-600 max-w-2xl mx-auto mb-6">
            Chez VGom Creation, nous proposons une gamme complète de services
            web pour répondre à tous vos besoins digitaux.
          </p>
        </div>

        {/* Bouton retour */}
        <button
          onClick={() => navigate('/')}
          className="flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-colors mb-8 group"
        >
          <ArrowLeft className="w-5 h-5 transform group-hover:-translate-x-1 transition-transform" />
          <span>Retour à l'accueil</span>
        </button>

        {/* Grille des services */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
          {servicesData.map((service) => (
            <motion.div
              key={service.id}
              className="flex flex-col items-center"
              initial={{ opacity: 0, scale: 0.8 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.6,
                ease: 'easeOut',
              }}
            >
              <div className="flex items-center justify-center mb-6">
                <img
                  src={service.image}
                  alt={service.title}
                  className="w-32 h-32 rounded-full object-cover"
                />
              </div>

              <h3 className="text-xl font-semibold mb-4 text-center">
                {service.title}
              </h3>

              <ul className="space-y-2 text-gray-600">
                {service.items.map((item, index) => (
                  <li key={index} className="flex items-start">
                    <span className="mr-2 text-eastern">•</span>
                    {item}
                  </li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Section informative sur les projets à vendre */}
      <div className="bg-gray-50 py-16 mt-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-4">
            Des projets clé en main, prêts à l'achat
          </h2>
          <p className="text-gray-600 max-w-3xl mx-auto mb-8">
            En plus de nos services sur mesure, nous proposons des projets web
            clé en main, disponibles dans notre boutique. Ces projets incluent
            la personnalisation des textes et des images, qui doivent être
            fournis par le client. Vous pouvez également ajouter des
            fonctionnalités supplémentaires selon vos besoins, celles-ci seront
            facturées séparément. N'hésitez pas à nous transmettre vos idées
            pour que nous puissions adapter le projet à vos attentes !
          </p>
          <Link
            to="/shop"
            className="bg-black text-white px-6 py-3 rounded-lg text-lg hover:bg-eastern transition"
          >
            Découvrir nos projets à vendre
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NosOffres;
