import React, { useState, useEffect } from "react";
import { ArrowUp } from "lucide-react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation(); // Permet de détecter les changements de route

  // Fonction pour gérer le défilement vers le haut
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Animation fluide
    });
  };

  // Fonction pour vérifier la position de défilement
  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll automatique lors d'un changement de page
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant", // Défilement immédiat au chargement de la page
    });
  }, [location]); // Déclenchement à chaque changement de route

  // Ajouter un écouteur d'événements pour le défilement
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility); // Nettoyage
    };
  }, []);

  return (
    <div>
      {isVisible && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-5 z-20 right-5 bg-violet-800 text-white p-3 rounded-full shadow-lg hover:bg-violetta transition duration-300"
          aria-label="Retour en haut"
        >
          <ArrowUp size={20} />
        </button>
      )}
    </div>
  );
};

export default ScrollToTop;
