import { Link } from "react-router-dom";

const Error404 = () => {
  return (
    <div className="mt-36 min-h-screen flex flex-col items-center justify-center bg-gray-900 text-gray-300">
      {/* Contenu principal */}
      <div className="text-center">
        <h1 className="text-9xl font-extrabold text-violet-500 mb-4">404</h1>
        <h2 className="text-2xl md:text-4xl font-bold mb-6 text-gray-100">
          Oups ! Page non trouvée
        </h2>
        <p className="text-gray-400 mb-8 text-lg">
          Il semble que la page que vous recherchez n'existe pas ou a été
          déplacée.
        </p>

        {/* Bouton pour retourner à l'accueil */}
        <Link
          to="/"
          className="px-6 py-3 bg-violet-600 hover:bg-violet-700 text-gray-100 rounded-lg shadow-lg transition duration-300"
        >
          Retour à l'accueil
        </Link>
      </div>

      {/* Animation ou image illustrative */}
      <div className="mt-12">
        <svg
          className="w-72 h-72 md:w-96 md:h-96 text-violet-500"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M9 18l6-6-6-6" />
        </svg>
      </div>
    </div>
  );
};

export default Error404;
