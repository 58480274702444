import React, { useState, useEffect, useRef } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { PersonAdd, Login, Logout } from '@mui/icons-material';
import logonoel from '../assets/logonoel.png';
import { logout } from '../api';
import Tooltip from '@mui/material/Tooltip';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const isAuthenticated = !!localStorage.getItem('jwt');
  const menuRef = useRef(null);

  const getLinkClass = ({ isActive }) => {
    return isActive
      ? 'border-b-2 border-white hover:text-gray-300'
      : 'hover:text-gray-300';
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const handleLogout = () => {
    logout();
    localStorage.removeItem('jwt');
    navigate('/');
  };

  // Ferme le menu lorsque l'utilisateur clique à l'extérieur
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <header className="fixed top-0 left-0 right-0 z-50 bg-black text-white font-['Space_Grotesk'] py-4">
      <div className="container mx-auto flex justify-between items-center px-4">
        {/* Logo */}
        <div className="flex items-center space-x-4">
          <Link to="/">
            <img src={logonoel} alt="Logo du site" className="h-16 md:h-28" />
          </Link>
        </div>

        {/* Conteneur des icônes pour mobile/tablette */}
        <div className="lg:hidden flex items-center space-x-4">
          {/* Icônes Inscription / Connexion / Déconnexion */}
          {!isAuthenticated && (
            <div className="flex items-center gap-4">
              <Tooltip title="Inscription">
                <PersonAdd
                  onClick={() => navigate('/inscription')}
                  className="cursor-pointer text-white hover:text-gray-300 transition-transform transform hover:scale-110"
                />
              </Tooltip>
              <Tooltip title="Connexion">
                <Login
                  onClick={handleLogin}
                  className="cursor-pointer text-white hover:text-gray-300 transition-transform transform hover:scale-110"
                />
              </Tooltip>
            </div>
          )}

          {isAuthenticated && (
            <Tooltip title="Déconnexion">
              <Logout
                onClick={handleLogout}
                className="cursor-pointer text-white hover:text-gray-300 transition-transform transform hover:scale-110"
              />
            </Tooltip>
          )}

          {/* Bouton Hamburger */}
          <button
            className="flex flex-col justify-center items-center space-y-1"
            onClick={toggleMenu}
          >
            <span className="block w-6 h-0.5 bg-white"></span>
            <span className="block w-6 h-0.5 bg-white"></span>
            <span className="block w-6 h-0.5 bg-white"></span>
          </button>
        </div>

        {/* Menu de navigation */}
        <nav
          ref={menuRef}
          className={`${menuOpen ? 'block' : 'hidden'} lg:flex rounded-full rounded-tr-none items-center space-x-8 absolute lg:static top-16 md:top-24 right-4 md:right-8 w-1/2 lg:w-auto opacity-80 bg-[#232323] lg:bg-transparent`}
        >
          <ul className="flex flex-col items-center space-y-2 lg:space-y-0 lg:flex-row lg:space-x-6 p-4 lg:p-0">
            <li>
              <NavLink
                to="/"
                className={getLinkClass}
                onClick={() => setMenuOpen(false)}
              >
                Accueil
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/portfolio"
                className={getLinkClass}
                onClick={() => setMenuOpen(false)}
              >
                Portfolio
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/blog"
                className={getLinkClass}
                onClick={() => setMenuOpen(false)}
              >
                Blog
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/nos-offres"
                className={getLinkClass}
                onClick={() => setMenuOpen(false)}
              >
                Nos offres
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/shop"
                className={getLinkClass}
                onClick={() => setMenuOpen(false)}
              >
                Shop
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/nous-contacter"
                className={getLinkClass}
                onClick={() => setMenuOpen(false)}
              >
                Contact
              </NavLink>
            </li>
          </ul>

          {/* Icônes pour le mode desktop */}
          <div className="hidden lg:flex items-center gap-4">
            {!isAuthenticated && (
              <>
                <Tooltip title="Inscription">
                  <PersonAdd
                    onClick={() => navigate('/inscription')}
                    className="cursor-pointer text-white hover:text-gray-300 transition-transform transform hover:scale-110"
                  />
                </Tooltip>
                <Tooltip title="Connexion">
                  <Login
                    onClick={handleLogin}
                    className="cursor-pointer text-white hover:text-gray-300 transition-transform transform hover:scale-110"
                  />
                </Tooltip>
              </>
            )}

            {isAuthenticated && (
              <Tooltip title="Déconnexion">
                <Logout
                  onClick={handleLogout}
                  className="cursor-pointer text-white hover:text-gray-300 transition-transform transform hover:scale-110"
                />
              </Tooltip>
            )}
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
