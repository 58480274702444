import { useContext } from 'react';
import { Eye, Heart, MessageSquare, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import { BlogContext } from '../context/BlogContext';

const BlogSection = () => {
  const { articles, loading, error } = useContext(BlogContext);

  if (loading) return <div>Chargement...</div>;
  if (error) return <div>{error}</div>;
  if (!articles.length) return <p>Aucun article disponible pour le moment.</p>;

  // Limiter les articles aux deux plus récents
  const recentArticles = articles.slice(0, 2);

  return (
    <div className="bg-white py-6 lg:py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold mb-6 lg:mb-2">Blog</h2>
        <Link
          to="/blog"
          className="flex items-center justify-end mb-6 text-gray-600 hover:text-eastern transition-colors group"
        >
          Voir tous les posts
          <ArrowRight
            className="ml-2 group-hover:translate-x-2 transition-transform"
            size={20}
          />
        </Link>
        <p className="text-gray-400 mb-8">Conseils et actualités</p>

        <div className="grid md:grid-cols-2 gap-8">
          {recentArticles.map((article) => {
            const imageUrl = article.image?.url
              ? `${article.image.url}`
              : null;

            return (
              <div key={article.id} className="cursor-pointer group">
                {/* Section Image */}
                {imageUrl ? (
                  <div className="w-full h-64 mb-4 overflow-hidden">
                    <img
                      src={imageUrl}
                      alt={article.titre || 'Image non disponible'}
                      className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                    />
                  </div>
                ) : (
                  <div className="w-full h-64 mb-4 bg-gray-200 flex items-center justify-center">
                    <span className="text-gray-500">
                      Aucune image disponible
                    </span>
                  </div>
                )}

                <Link to={`/blog`}>
                  <h3 className="text-xl font-semibold mb-2 group-hover:text-eastern transition-colors">
                    {article.titre}
                  </h3>
                </Link>

                {/* Affichage de la date de publication */}
                <p className="text-sm text-gray-500 mb-2">
                  Publié le {new Date(article.createdAt).toLocaleDateString()}
                </p>

                {/* Section d'icônes pour vues, likes et commentaires (affichage uniquement) */}
                <div className="flex items-center text-sm text-gray-500 space-x-4">
                  <div className="flex items-center">
                    <Eye size={16} className="mr-1" />
                    <span>{article.vue_count}</span>
                  </div>
                  <div className="flex items-center">
                    <Heart
                      size={16}
                      className={`mr-1 ${
                        article.hasLiked
                          ? 'text-red-500 fill-current'
                          : 'text-gray-400'
                      }`}
                    />
                    <span>{article.likes_count}</span>
                  </div>
                  <div className="flex items-center">
                    <MessageSquare size={16} className="mr-1" />
                    <span>{article.commentaire_count || 0}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BlogSection;
