import React from 'react';

const CGV = () => {
  return (
    <div className="container mt-32 mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold text-center mb-6">
        Conditions Générales de Vente (CGV)
      </h1>
      <p className="text-gray-600 text-center mb-8 max-w-3xl mx-auto">
        Les présentes Conditions Générales de Vente régissent les transactions
        réalisées sur le site VGom Creation. En effectuant une commande, vous
        acceptez ces conditions dans leur intégralité.
      </p>

      <section>
        <h2 className="text-2xl font-semibold mt-6 mb-2">1. Identification</h2>
        <p>
          <strong>Nom de l’entreprise :</strong> VGom Creation
        </p>
        <p>
          <strong>Adresse :</strong> 7 Rue de la Louette, 91150 Étampes, France
        </p>
        <p>
          <strong>Email :</strong> contact@vgomcreation.fr
        </p>
        <p>
          <strong>Téléphone :</strong> 07 67 60 34 49
        </p>
        <p>
          <strong>Numéro SIRET :</strong> 93380665500017
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-6 mb-2">
          2. Produits et services
        </h2>
        <p>
          VGom Creation propose des modèles de sites web, fichiers source et
          services de personnalisation. Chaque produit ou service est décrit sur
          sa page dédiée.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-6 mb-2">3. Prix</h2>
        <p>
          Les prix indiqués sont en euros, toutes taxes comprises (TTC). Les
          frais supplémentaires éventuels sont indiqués lors de la commande.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-6 mb-2">4. Paiement</h2>
        <p>
          Les paiements sont effectués via Stripe et sont entièrement sécurisés.
          Les moyens acceptés incluent les cartes bancaires.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-6 mb-2">5. Livraison</h2>
        <p>
          Les fichiers achetés sont livrés par email sous un délai d’une heure.
          En cas de non-réception, veuillez nous contacter à
          contact@vgomcreation.fr.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-6 mb-2">
          6. Droit de rétractation
        </h2>
        <p>
          Conformément à la loi, les produits numériques ne peuvent pas faire
          l’objet d’un droit de rétractation une fois téléchargés, sauf en cas
          de défaut du produit.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-6 mb-2">7. Garanties</h2>
        <p>
          Les produits bénéficient des garanties légales, notamment la garantie
          de conformité et la garantie contre les vices cachés.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-6 mb-2">
          8. Propriété intellectuelle
        </h2>
        <p>
          Les fichiers achetés sont destinés à un usage personnel ou
          professionnel par le client et ne peuvent être revendus ou partagés
          sans autorisation écrite.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-6 mb-2">9. Garantie</h2>
        <p>
          Tous les produits numériques vendus par VGom Creation bénéficient des
          garanties légales prévues par la loi française :
        </p>
        <ul>
          <li>
            <strong>Garantie légale de conformité : </strong>Les fichiers livrés
            doivent être conformes à leur description et utilisables comme
            prévu. Cette garantie est valable pendant 2 ans à compter de la
            livraison.
          </li>
          <li>
            <strong>Garantie contre les vices cachés : </strong>Les fichiers ne
            doivent pas présenter de défauts cachés rendant leur utilisation
            impossible. Si un vice caché est constaté, le client peut demander
            un remboursement ou une réduction de prix.
          </li>
        </ul>
        <p>
          Pour toute question ou réclamation, veuillez nous contacter à{' '}
          <a
            href="mailto:contact@vgomcreation.fr"
            className="text-blue-500 hover:underline"
          >
            contact@vgomcreation.fr
          </a>
          .
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-6 mb-2">10. Litiges</h2>
        <p>
          En cas de litige, les tribunaux compétents sont ceux du ressort du
          siège social de VGom Creation. Les présentes CGV sont régies par la
          loi française.
        </p>
      </section>
    </div>
  );
};

export default CGV;
