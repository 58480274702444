import React, { useState, useEffect } from 'react';
import { getAllTestimonials, submitTestimonial } from '../api';
import BackgroundGrid from '../animations/Gridbg';

const Temoignage = () => {
  const [temoignages, setTemoignages] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [rating, setRating] = useState(0); // Note sélectionnée
  const [hoverRating, setHoverRating] = useState(0); // Note au survol

  // Récupération des témoignages
  useEffect(() => {
    const fetchTemoignages = async () => {
      try {
        const data = await getAllTestimonials(); // Récupérer les témoignages depuis l'API

        console.log('Témoignages reçus :', data.data); // Inspectez la structure des données reçues
        setTemoignages(data.data);
      } catch (error) {
        setError('Erreur lors de la récupération des témoignages.');
      }
    };

    fetchTemoignages();
  }, []);

  // Soumission d'un nouveau témoignage
  const handleSubmitTemoignage = async (formData) => {
    setIsSubmitting(true);
    setError(null);

    try {
      const payload = {
        nom_du_client: formData.nom_du_client,
        contenu_du_temoignage: formData.contenu_du_temoignage,
        note: formData.note,
      };

      const response = await submitTestimonial(payload);
      console.log('Témoignage soumis :', response);

      const updatedTestimonials = await getAllTestimonials();
      setTemoignages(updatedTestimonials.data);
    } catch (error) {
      console.error('Erreur lors de la soumission :', error.message);
      setError(error.message || 'Erreur lors de la soumission du témoignage.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Affichage des étoiles
  const renderStars = (note, isInteractive = false) => {
    return Array(5)
      .fill(0)
      .map((_, index) => {
        const starIndex = index + 1;

        return (
          <span
            key={index}
            onMouseEnter={
              isInteractive ? () => setHoverRating(starIndex) : undefined
            }
            onMouseLeave={isInteractive ? () => setHoverRating(0) : undefined}
            onClick={isInteractive ? () => setRating(starIndex) : undefined}
            className={`text-xl cursor-pointer ${
              starIndex <= (hoverRating || note)
                ? 'text-yellow-400'
                : 'text-gray-300'
            }`}
          >
            ★
          </span>
        );
      });
  };

  return (
    <div className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold mb-4">Témoignages</h2>
        <p className="text-gray-600 mb-8">Ce que disent nos clients</p>

        {error && <p className="text-red-500 text-center mb-4">{error}</p>}

        <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-8 max-w-6xl mx-auto">
          {temoignages.map((temoignage) => (
            <div
              key={temoignage.id}
              className="relative bg-white p-6 rounded-lg shadow-lg"
            >
              <BackgroundGrid
                color="#000000"
                cellSize="25px"
                strokeWidth="1px"
                fade={true}
                className="absolute inset-0"
              />
              <div className="relative z-10">
                <div className="mb-4">{renderStars(temoignage.note)}</div>
                <p className="text-gray-700 mb-4">
                  {temoignage.contenu_du_temoignage}
                </p>
                <p className="font-bold">{temoignage.nom_du_client}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-12 max-w-xl mx-auto">
          <h3 className="text-2xl font-bold mb-4">Partagez votre expérience</h3>
          <form
            onSubmit={async (e) => {
              e.preventDefault();

              const formData = {
                nom_du_client: e.target.nom_du_client.value,
                contenu_du_temoignage: e.target.contenu_du_temoignage.value,
                note: rating,
              };

              await handleSubmitTemoignage(formData);
              e.target.reset(); // Réinitialisez le formulaire
              setRating(0); // Réinitialisez la note
            }}
          >
            <div>
              <label
                htmlFor="nom_du_client"
                className="block text-gray-700 mb-2"
              >
                Nom
              </label>
              <input
                type="text"
                id="nom_du_client"
                name="nom_du_client"
                required
                className="w-full p-2 border rounded-lg"
              />
            </div>
            <div>
              <label
                htmlFor="contenu_du_temoignage"
                className="block text-gray-700 mb-2"
              >
                Votre témoignage
              </label>
              <textarea
                id="contenu_du_temoignage"
                name="contenu_du_temoignage"
                required
                rows="4"
                className="w-full p-2 border rounded-lg"
              ></textarea>
            </div>
            <div>
              <label className="block text-gray-700 mb-1">Note</label>
              <div className="flex mb-4">{renderStars(rating, true)}</div>
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="bg-black text-white px-6 py-2 rounded-lg hover:bg-eastern disabled:opacity-50"
            >
              {isSubmitting ? 'Envoi en cours...' : 'Envoyer'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Temoignage;
