import React from 'react';
import { motion } from "framer-motion";
import Banner from '../components/Banner';
import BlogSection from '../components/BlogSection';
import ProjectSection from '../components/ProjectSection';
import Temoignage from '../components/Temoignage';
import BoutonsPartage from '../components/BoutonsPartage';

// Composant wrapper pour l'animation
const AnimatedSection = ({ children, delay = 0 }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ 
        opacity: 1, 
        y: 0,
        transition: {
          duration: 0.8,
          delay: delay,
          ease: "easeOut"
        }
      }}
      viewport={{ 
        once: true,
        margin: "-100px"
      }}
    >
      {children}
    </motion.div>
  );
};

function Home() {
  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-grow">  
      <BoutonsPartage /> 
        <AnimatedSection>
          <Banner />
        </AnimatedSection>

        <AnimatedSection delay={0.2}>
          <ProjectSection />
        </AnimatedSection>

        <AnimatedSection delay={0.3}>
          <BlogSection />
        </AnimatedSection>

        <AnimatedSection delay={0.4}>
          <Temoignage />
        </AnimatedSection>
      </main>
    </div>
  );
}

export default Home;