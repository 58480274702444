import React, { useState } from 'react';
import { Mail, MapPin, Clock, Smartphone, ArrowLeft } from 'lucide-react';
import Particles from '../animations/Particles';
import { useNavigate } from 'react-router-dom';

const NousContacter = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    prenom: '',
    nom: '',
    email: '',
    message: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setMessage('Envoi en cours...');

    try {
      const formDataToSend = new FormData();
      formDataToSend.append(
        'access_key',
        process.env.REACT_APP_WEB3FORM_API_KEY
      );
      formDataToSend.append('prenom', formData.prenom);
      formDataToSend.append('nom', formData.nom);
      formDataToSend.append('email', formData.email);
      formDataToSend.append('message', formData.message);

      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        body: formDataToSend,
      });

      const data = await response.json();
      console.log('Réponse Web3Forms :', data);

      if (data.success) {
        setMessage('Message envoyé avec succès !');
        setFormData({ prenom: '', nom: '', email: '', message: '' });
      } else {
        setMessage(data.message || 'Une erreur est survenue.');
      }
    } catch (error) {
      setMessage('Erreur de connexion. Veuillez réessayer.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCalendly = () => {
    window.open('https://calendly.com/vincentvaiti/30min', '_blank');
  };

  return (
    <div className="relative min-h-screen w-full py-20">
      <div className="text-center pt-10 md:pt-20">
        <h1 className="text-4xl font-bold mb-4">Nous Contacter</h1>
        <p className="text-gray-500 max-w-xl mx-auto">
          Une question ? Besoin d'information ? Notre équipe est à votre
          disposition pour répondre à vos besoins !
        </p>
      </div>

      {/* Bouton retour */}
      <div className="container mx-auto px-4">
        <button
          onClick={() => navigate('/')}
          className="flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-colors mb-8 group"
        >
          <ArrowLeft className="w-5 h-5 transform group-hover:-translate-x-1 transition-transform" />
          <span>Retour à l'accueil</span>
        </button>
      </div>

      <div className="flex flex-1 flex-col md:flex-row w-[80vw] mx-auto pt-12 md:pt-16 gap-6">
        <div className="flex flex-col gap-10 md:gap-20 lg:gap-20 relative">
          <Particles
            className="absolute inset-0 z-0"
            quantity={50}
            staticity={60}
            ease={80}
          />
          <div className="p-6 flex flex-col items-center bg-gradient-to-br from-purple-800 via-indigo-900 to-purple-600 text-white rounded-lg shadow-lg">
            <div className="flex items-center gap-3 mb-4">
              <MapPin size={32} />
              <h3 className="text-xl font-bold">Localisation</h3>
            </div>
            <p className="text-center">Paris & Périphérie, France</p>
          </div>
          <div className="p-6 flex flex-col items-center bg-gradient-to-br from-purple-800 via-indigo-900 to-purple-600 text-white rounded-lg shadow-lg">
            <div className="flex items-center gap-3 mb-4">
              <Smartphone size={32} />
              <h3 className="text-xl font-bold">Téléphone</h3>
            </div>
            <p className="text-center">07 67 60 34 49</p>
          </div>
          <div className="p-6 flex flex-col items-center bg-gradient-to-br from-purple-800 via-indigo-900 to-purple-600 text-white rounded-lg shadow-lg">
            <div className="flex items-center gap-3 mb-4">
              <Mail size={32} />
              <h3 className="text-xl font-bold">E-mail</h3>
            </div>
            <p className="text-center">contact@vgomcreation.fr</p>
          </div>
          <div className="p-6 flex flex-col items-center bg-gradient-to-br from-purple-800 via-indigo-900 to-purple-600 text-white rounded-lg shadow-lg">
            <div className="flex items-center gap-3 mb-4">
              <Clock size={32} />
              <h3 className="text-xl font-bold">Horaires</h3>
            </div>
            <p className="text-center">Du lundi au vendredi : 9 h - 17 h</p>
            <p className="text-center">Week-end & jours fériés : Fermé</p>
          </div>
        </div>
        <div className="flex-1 p-7 lg:p-12 bg-white rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold text-gray-800 mb-6">
            Formulaire de Contact
          </h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label
                  htmlFor="prenom"
                  className="block text-gray-600 mb-2 font-medium"
                >
                  Prénom *
                </label>
                <input
                  type="text"
                  id="prenom"
                  name="prenom"
                  value={formData.prenom}
                  onChange={handleChange}
                  required
                  className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
              </div>
              <div>
                <label
                  htmlFor="nom"
                  className="block text-gray-600 mb-2 font-medium"
                >
                  Nom *
                </label>
                <input
                  type="text"
                  id="nom"
                  name="nom"
                  value={formData.nom}
                  onChange={handleChange}
                  required
                  className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-gray-600 mb-2 font-medium"
              >
                E-mail *
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
            </div>
            <div>
              <label
                htmlFor="message"
                className="block text-gray-600 mb-2 font-medium"
              >
                Message *
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                rows="4"
                className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
              ></textarea>
            </div>
            <div className="relative flex justify-center">
              <Particles
                className="absolute inset-0 z-10 pointer-events-none"
                quantity={20}
                staticity={60}
                ease={80}
              />
              <button
                type="submit"
                disabled={isSubmitting}
                className={`w-full xl:w-1/3 bg-gradient-to-br from-purple-800 to-indigo-600 text-white py-3 rounded-lg font-medium transition-all duration-300 
                hover:from-indigo-700 hover:to-purple-600 hover:shadow-lg disabled:opacity-50 relative`}
              >
                {isSubmitting ? 'Envoi en cours...' : 'Envoyer'}
              </button>
            </div>
            {message && (
              <p
                className={`text-center mt-4 font-medium ${
                  message.includes('succès') ? 'text-green-500' : 'text-red-500'
                }`}
              >
                {message}
              </p>
            )}
            <div className="relative flex justify-center">
              <Particles
                className="absolute inset-0 z-0 pointer-events-none"
                quantity={20}
                staticity={60}
                ease={80}
              />
              <button
                type="button"
                onClick={handleCalendly}
                className="w-full xl:w-1/3 mt-4 bg-gradient-to-br from-indigo-600 to-purple-800 text-white py-3 rounded-lg font-medium transition-all duration-300 hover:from-purple-600 hover:to-indigo-700 hover:shadow-lg"
              >
                Planifier un rendez-vous
              </button>
            </div>
            <p className="text-sm text-gray-500 text-left mt-6">
              Les champs marqués d'un astérisque (*) sont obligatoires.
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NousContacter;
