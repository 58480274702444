import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Banner = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="relative pt-16 max-w-full overflow-hidden">
      <Slider {...settings} className="mx-auto">
        <div>
          <img src="/images/1.webp" alt="Bureau de Vincent" className="w-full h-[500px] object-cover" />
        </div>
        <div>
          <img src="/images/2.webp" alt="Poste de travail" className="w-full h-[500px] object-cover" />
        </div>
      </Slider>
      
      <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col justify-center items-center text-white p-4">
      <h1 className="text-4xl md:text-5xl font-bold mb-4 text-center bg-gradient-to-b from-violetta to-eastern bg-clip-text text-transparent">Création de Sites Web</h1>
        <p className="text-lg md:text-xl max-w-3xl text-center mb-6">
        Bienvenue chez VGom Creation, votre partenaire de confiance pour la création de sites Web modernes et innovants. Grâce aux dernières technologies, nous nous engageons à offrir à nos clients une expérience unique tout en valorisant et mettant en lumière votre activité.
        </p>
      </div>
    </div>
  );
};

export default Banner;