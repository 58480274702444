import React, { useState, useEffect } from 'react';
import { getAllProjects } from '../api';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const Portfolio = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const data = await getAllProjects();
        console.log('Réponse de l’API pour les projets :', data);

        const formattedProjects = data.data.map((project) => ({
          id: project.id,
          title: project.titre,
          image: project.image?.formats?.medium?.url
            ? `${project.image.formats.medium.url}`
            : `${project.image?.url}`,
          url: project.url_projet,
        }));

        setProjects(formattedProjects);
      } catch (err) {
        setError('Erreur lors de la récupération des projets.');
      }
    };

    fetchProjects();
  }, []);

  if (error) {
    return <p className="text-red-500 text-center mt-8">{error}</p>;
  }

  return (
    <div className="min-h-screen bg-white py-20">
      {/* Section Titre */}
      <div className="text-center pt-10 md:pt-20">
        <h1 className="text-4xl font-bold mb-4 flex items-center justify-center gap-4">
          <span>Mon Portfolio</span>
        </h1>
        <p className="text-gray-500 max-w-xl mx-auto mb-6">
          Bienvenue sur mon portfolio ! Cliquez sur un projet pour en savoir
          plus. Certains projets sont également disponibles à l'achat dans la
          section Shop.
        </p>
      </div>

      {/* Bouton retour */}
      <div className="container mx-auto px-4">
        <button
          onClick={() => navigate('/')}
          className="flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-colors mb-8 group"
        >
          <ArrowLeft className="w-5 h-5 transform group-hover:-translate-x-1 transition-transform" />
          <span>Retour à l'accueil</span>
        </button>
      </div>

      {/* Grille de Projets */}
      <div className="container mx-auto px-4 grid gap-8 md:grid-cols-2 xl:grid-cols-3">
        {projects.map((project) => (
          <a
            href={project.url}
            key={project.id}
            target="_blank"
            rel="noopener noreferrer"
            className="relative bg-white rounded-lg overflow-hidden shadow-lg transition-transform transform hover:scale-105"
          >
            {project.image ? (
              <img
                src={project.image}
                alt={project.title}
                className="w-full h-64 object-cover"
              />
            ) : (
              <div className="w-full h-64 bg-gray-200 flex items-center justify-center">
                <span className="text-gray-500">Aucune image</span>
              </div>
            )}
            <div className="absolute inset-0 bg-black bg-opacity-30 opacity-0 hover:opacity-100 flex items-center justify-center transition-opacity">
              <h2 className="text-white font-semibold text-lg">
                {project.title}
              </h2>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
