import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { BlogProvider } from './context/BlogContext';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Inscription from './pages/Inscription';
import Connexion from './pages/Connexion';
import NosOffres from './pages/NosOffres';
import Portfolio from './pages/Portfolio';
import BlogPage from './pages/BlogPage';
import ArticleDetails from './pages/ArticleDetails';
import NousContacter from './pages/NousContacter';
import Error404 from './pages/Error404';
import CGU from './pages/CGU';
import CGV from './pages/CGV';
import MentionsLegales from './pages/MentionsLegales';
import PolitiquesDeConfidentialites from './pages/PolitiquesDeConfidentialites';
import ScrollToTop from './components/ScrollToTop';
import Shop from './pages/Shop';

function App() {
  return (
    <BlogProvider>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/inscription" element={<Inscription />} />
        <Route path="/login" element={<Connexion />} />
        <Route path="/nos-offres" element={<NosOffres />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/article/:documentId" element={<ArticleDetails />} />
        <Route path="/nous-contacter" element={<NousContacter />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/cgu" element={<CGU />} />
        <Route path="/cgv" element={<CGV />} />
        <Route path="/mentions-legales" element={<MentionsLegales />} />
        <Route
          path="/politique-confidentialite"
          element={<PolitiquesDeConfidentialites />}
        />
        <Route path="*" element={<Error404 />} />
        <Route path="/404" element={<Error404 />} />
      </Routes>
      <Footer />
    </BlogProvider>
  );
}

export default App;
