import React from 'react';

const CGU = () => {
  return (
    <div className="container mt-32 mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold text-center mb-6">Conditions Générales d'Utilisation (CGU)</h1>
      <p className="text-gray-600 text-center mb-8 max-w-3xl mx-auto">
        Les présentes Conditions Générales d’Utilisation (CGU) régissent l'accès et l'utilisation du site VGom Creation. 
        En naviguant ou en effectuant une commande sur ce site, vous acceptez ces conditions dans leur intégralité.
      </p>

      <section>
        <h2 className="text-2xl font-semibold mt-6 mb-2">1. Objet des CGU</h2>
        <p>Les présentes CGU définissent les modalités d'utilisation des services proposés par VGom Creation, ainsi que les droits et obligations des utilisateurs et de VGom Creation.</p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-6 mb-2">2. Accès au site et services</h2>
        <p>
          L'accès au site est ouvert à toute personne, mais certaines sections ou services peuvent nécessiter un compte utilisateur. 
          Les utilisateurs doivent fournir des informations exactes lors de la création du compte et s’assurer de leur mise à jour.
          Pour passer commande, les utilisateurs doivent être âgés de 18 ans ou plus, ou obtenir une autorisation de leurs parents ou tuteurs légaux.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-6 mb-2">3. Commandes et transactions</h2>
        <p>
          Les paiements effectués sur VGom Creation sont sécurisés via Stripe. Une fois la commande validée, 
          un reçu Stripe est envoyé automatiquement à l'adresse e-mail fournie. Les fichiers commandés (code source) sont livrés par e-mail dans un délai d'une heure. 
          En cas de non-réception, contactez-nous à <a href="mailto:contact@vgomcreation.fr" className="text-blue-500 underline">contact@vgomcreation.fr</a>.
        </p>
        <p>
          Les produits achetés sont destinés à un usage personnel ou professionnel par le client et ne peuvent être revendus, redistribués ou partagés sans autorisation écrite.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-6 mb-2">4. Comportement de l’utilisateur</h2>
        <p>Les utilisateurs s'engagent à :</p>
        <ul className="list-disc ml-6">
          <li>Respecter les lois et règlements en vigueur ;</li>
          <li>Ne pas utiliser le site à des fins frauduleuses ou illégales ;</li>
          <li>Ne pas tenter d’accéder à des données ou des fonctionnalités non autorisées du site.</li>
        </ul>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-6 mb-2">5. Propriété intellectuelle</h2>
        <p>
          Tout le contenu disponible sur VGom Creation (textes, images, logos, graphismes, fichiers source) est protégé par le droit d'auteur. 
          Toute reproduction, modification ou redistribution sans consentement écrit est strictement interdite.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-6 mb-2">6. Liens externes</h2>
        <p>
          VGom Creation peut contenir des liens vers des sites externes. Nous déclinons toute responsabilité concernant le contenu ou les services proposés par ces sites.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-6 mb-2">7. Limitation de responsabilité</h2>
        <p>
          VGom Creation s'efforce de maintenir le site fonctionnel et les informations à jour, mais ne peut garantir l'absence d'erreurs ou d'interruptions. 
          Nous ne saurions être tenus responsables des dommages résultant de l’utilisation du site ou des produits fournis.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-6 mb-2">8. Modification des CGU</h2>
        <p>
          VGom Creation se réserve le droit de modifier ces CGU à tout moment. Les utilisateurs sont invités à consulter régulièrement cette page 
          pour prendre connaissance des éventuelles modifications. Toute utilisation du site après modification implique l’acceptation des nouvelles CGU.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-6 mb-2">9. Loi applicable et juridiction</h2>
        <p>
          Ces CGU sont régies par la loi française. En cas de litige, seuls les tribunaux du ressort du siège social de VGom Creation seront compétents.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-6 mb-2">10. Contact</h2>
        <p>
          Pour toute question ou demande relative aux CGU ou à l’utilisation de nos services, vous pouvez nous contacter à : 
          <a href="mailto:contact@vgomcreation.fr" className="text-blue-500 underline"> contact@vgomcreation.fr</a>.
        </p>
      </section>
    </div>
  );
};

export default CGU;
