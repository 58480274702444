import React from 'react';
import { Link } from 'react-router-dom';

const MentionsLegales = () => {
  return (
    <div className="container mt-32 mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold text-center mb-6">Mentions Légales</h1>
      <p className="text-gray-600 text-center mb-8 max-w-3xl mx-auto">
        Conformément à l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, 
        voici les informations relatives à l'éditeur et à l'hébergeur du site VGom Creation.
      </p>

      <section>
        <h2 className="text-2xl font-semibold mt-6 mb-2">1. Éditeur du site</h2>
        <p><strong>Nom de l’entreprise :</strong> VGom Creation</p>
        <p><strong>Forme juridique :</strong> Micro-entreprise</p>
        <p><strong>Adresse :</strong> 7 Rue de la Louette, 91150 Étampes, France</p>
        <p><strong>Numéro de téléphone :</strong> 07 67 60 34 49</p>
        <p><strong>Adresse e-mail :</strong> contact@vgomcreation.fr</p>
        <p><strong>Responsable de publication :</strong> M. Vaïtilingom Vincent</p>
        <p><strong>Numéro SIRET :</strong> 93380665500017</p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-6 mb-2">2. Hébergeur du site</h2>
        <p><strong>Nom de l’hébergeur :</strong> Vercel</p>
        <p><strong>Adresse :</strong> 340 S Lemon Ave #4133, Walnut, CA 91789, United States</p>
        <p><strong>Adresse e-mail :</strong> support@vercel.com</p>
        <p><strong>Site Web :</strong> <a href="https://vercel.com" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">https://vercel.com</a></p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-6 mb-2">3. Propriété intellectuelle</h2>
        <p>
          Le site VGom Creation et tout son contenu (textes, images, graphismes, logos, vidéos, icônes, etc.) sont la propriété exclusive de VGom Creation 
          ou de ses partenaires, sauf mention contraire. Toute reproduction, représentation, modification, publication, adaptation totale ou partielle 
          de ces éléments, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-6 mb-2">4. Responsabilité</h2>
        <p>
          VGom Creation s’efforce de fournir des informations aussi précises que possible. Toutefois, l’entreprise ne pourra être tenue responsable des omissions, 
          inexactitudes ou carences dans la mise à jour des informations, qu’elles soient de son fait ou de celui des tiers partenaires qui fournissent ces informations.
        </p>
        <p>
          L’utilisateur est seul responsable de l’utilisation qu’il fait des contenus présents sur le site. VGom Creation ne pourra être tenu responsable des dommages directs ou indirects causés à l’utilisateur ou à son matériel.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-6 mb-2">5. Politique de confidentialité</h2>
        <p>
          VGom Creation s’engage à respecter la vie privée de ses utilisateurs. Pour plus de détails sur la collecte et l’utilisation de vos données personnelles, 
          veuillez consulter notre <Link to="/politique-confidentialite" className="text-blue-500 hover:underline">Politique de confidentialité</Link>.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-6 mb-2">6. Cookies</h2>
        <p>
          Le site utilise des cookies pour améliorer l’expérience utilisateur et mesurer l’audience. Vous pouvez gérer vos préférences relatives aux cookies via les 
          paramètres de votre navigateur. Pour en savoir plus, veuillez consulter notre <Link to="/politique-confidentialite" className="text-blue-500 hover:underline">Politique de confidentialité</Link>.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-6 mb-2">7. Loi applicable</h2>
        <p>
          Les présentes mentions légales sont régies par la loi française. Tout litige relatif à l’utilisation du site sera soumis à la compétence exclusive des tribunaux français, 
          dans le ressort du siège social de VGom Creation.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-6 mb-2">8. Contact</h2>
        <p>
          Pour toute question relative au site ou aux mentions légales, vous pouvez nous contacter à :
          <a href="mailto:contact@vgomcreation.fr" className="text-blue-500 hover:underline ml-1">contact@vgomcreation.fr</a>.
        </p>
      </section>
    </div>
  );
};

export default MentionsLegales;
