import React from "react";

const Accordion = ({ title, children, isOpen, onToggle }) => {
  return (
    <div
      className={`border rounded-lg shadow w-full overflow-hidden transition-all duration-300 ${
        isOpen ? "bg-gray-50 border-gray-300" : "bg-gray-100 border-gray-200"
      }`}
    >
      <button
        onClick={onToggle}
        className={`w-full text-left p-4 font-bold transition-colors ${
          isOpen ? "bg-gray-200 hover:bg-gray-300" : "bg-gray-100 hover:bg-gray-200"
        }`}
      >
        {title}
      </button>
      <div
        className={`transition-all duration-300 ${
          isOpen ? "max-h-screen p-4" : "max-h-0 p-0"
        }`}
      >
        <div
          className={`transition-opacity duration-300 ${
            isOpen ? "opacity-100" : "opacity-0"
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
